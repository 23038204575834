<template>
<div class="order-details">
  <router-link to="/" class="back-button">Back to Orders</router-link>

  <h1>Order Details</h1>
  <div v-if="order" class="order-info">
    <p><strong>Order ID:</strong> {{ order.order_id }}</p>
    <p><strong>Note:</strong> {{ order.note }}</p>
    <p>
      <strong>Status:</strong>
      <select v-model="selectedStatus">
        <option :value="true">Completed</option>
        <option :value="false">Not Completed</option>
      </select>
      <button @click="saveStatus" class="save-button">Save</button>
    </p>
    <div v-if="successMessage" class="success-message">
      {{ successMessage }}
    </div>
  </div>
  <div v-else>
    <p>{{ errorMessage || 'Loading order details...' }}</p>
  </div>

  <h2>Comments</h2>
  <table v-if="comments.length > 0" class="comments-table">
    <thead>
      <tr>
        <th>Comment</th>
        <th>Timestamp</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="comment in comments" :key="comment.id">
        <td>{{ comment.comment_text }}</td>
        <td>{{ new Date(comment.created_at).toLocaleString() }}</td>
        <td>
          <button @click="confirmDelete(comment.id)" class="delete-button">Delete</button>
        </td>
      </tr>
    </tbody>
  </table>
  <p v-else>No comments yet.</p>

  <div class="comment-box">
    <textarea v-model="newComment" placeholder="Add a comment"></textarea>
    <button @click="addComment" :disabled="!newComment">Submit</button>
  </div>

  <div v-if="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
</div>
</template>

<script>
import { supabase } from '../supabase';

export default {
props: ['id'],
data() {
  return {
    user: null,
    order: null,
    comments: [],
    newComment: '',
    selectedStatus: null,
    errorMessage: '',
    successMessage: ''
  };
},
async created() {
  console.log("Order ID received:", this.id);

  if (!this.id) {
    this.errorMessage = 'Invalid order ID. Please go back and try again.';
    return;
  }

  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) throw error;

    if (session) {
      this.user = session.user;

      console.log(`Fetching order with ID: ${this.id}`);
      const { data: orderData, error: orderError } = await supabase
        .from('Notes')
        .select('*')
        .eq('id', this.id)
        .single();

      if (orderError) {
        console.error('Error fetching order:', orderError);
        this.errorMessage = `Failed to load order details. ${orderError.message}`;
      } else {
        this.order = orderData;
        this.selectedStatus = orderData.status;
        console.log("Order details:", this.order);

        // Fetch comments related to the order
        await this.fetchComments(orderData.order_id);
      }
    } else {
      this.errorMessage = 'You must be logged in to view this content.';
    }
  } catch (err) {
    console.error('Error retrieving session or loading data:', err);
    this.errorMessage = 'Failed to load order details due to an error.';
  }
},
methods: {
  async fetchComments(order_id) {
    try {
      console.log("Fetching comments for order ID:", order_id);
      const { data: commentsData, error: commentsError } = await supabase
        .from('Comments')
        .select('*')
        .eq('order_id', order_id);

      if (commentsError) {
        console.error('Error fetching comments:', commentsError);
        this.errorMessage = 'Failed to load comments.';
      } else {
        console.log("Comments fetched:", commentsData);
        this.comments = commentsData;
      }
    } catch (err) {
      console.error('Failed to fetch comments:', err);
      this.errorMessage = 'Failed to load comments due to an error.';
    }
  },
  async saveStatus() {
    const { error } = await supabase
      .from('Notes')
      .update({ status: this.selectedStatus })
      .eq('id', this.id);

    if (error) {
      console.error('Error updating status:', error);
      this.errorMessage = 'Failed to update status. Please try again.';
    } else {
      this.successMessage = 'Status successfully updated!';
      this.order.status = this.selectedStatus;

      setTimeout(() => {
        this.successMessage = '';
      }, 3000);
    }
  },
  async addComment() {
    if (!this.newComment) {
      this.errorMessage = 'Comment cannot be empty.';
      return;
    }

    try {
      const { data, error } = await supabase
        .from('Comments')
        .insert([
          { 
            order_id: this.order.order_id, 
            comment_text: this.newComment,
          }
        ])
        .select();  // Ensure that the inserted data is returned

      if (error) {
        console.error('Error adding comment:', error);
        this.errorMessage = `Failed to add comment. ${error.message}`;
      } else if (data && data.length > 0) {
        console.log("New comment added:", data[0]);
        this.comments.push(data[0]);
        this.newComment = '';  // Clear the comment input field
        this.errorMessage = ''; // Clear any error messages
      } else {
        this.errorMessage = 'Failed to add comment. No data returned.';
      }
    } catch (err) {
      console.error('Failed to add comment:', err);
      this.errorMessage = `Failed to add comment due to an error. ${err.message}`;
    }
  },
  async confirmDelete(commentId) {
    const confirmed = confirm("Are you sure you want to delete this comment?");
    if (confirmed) {
      await this.deleteComment(commentId);
    }
  },
  async deleteComment(commentId) {
    try {
      const { error } = await supabase
        .from('Comments')
        .delete()
        .eq('id', commentId);

      if (error) {
        console.error('Error deleting comment:', error);
        this.errorMessage = `Failed to delete comment. ${error.message}`;
      } else {
        console.log(`Comment with ID ${commentId} deleted.`);
        this.comments = this.comments.filter(comment => comment.id !== commentId);
        this.errorMessage = ''; // Clear any error messages
      }
    } catch (err) {
      console.error('Failed to delete comment:', err);
      this.errorMessage = `Failed to delete comment due to an error. ${err.message}`;
    }
  }
},
};
</script>


<style scoped>
/* Professional and Modern Styling */
.order-details {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.back-button {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
}

.back-button:hover {
  background-color: #0056b3;
}

.order-header h1 {
  text-align: center;
  color: #333333;
  font-size: 28px;
  margin-bottom: 20px;
}

.order-info {
  margin-bottom: 20px;
}

.order-field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.order-field label {
  font-weight: bold;
  color: #555555;
}

.order-field span {
  color: #333333;
}

.status-select {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-right: 10px;
}

.save-button {
  padding: 8px 15px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.save-button:hover {
  background-color: #218838;
}

.success-message {
  margin-top: 10px;
  padding: 10px;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}

.comments-section {
  margin-top: 30px;
}

.comments-section h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333333;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

.comments-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.comments-table th,
.comments-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.comments-table th {
  background-color: #f8f9fa;
  color: #333333;
  font-weight: bold;
}

.comments-table td {
  background-color: #ffffff;
  color: #555555;
}

.delete-button {
  padding: 6px 10px;
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.delete-button:hover {
  background-color: #c82333;
}

.comment-box {
  margin-top: 20px;
}

.comment-box textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  font-size: 14px;
  margin-bottom: 10px;
  resize: vertical;
}

.submit-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.submit-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.footer {
  margin-top: 30px;
  padding: 15px;
  background-color: #f8f9fa;
  text-align: center;
  font-size: 14px;
  color: #555555;
  border-radius: 5px;
}
</style>
<template>
<div id="app">
  <header>
    <h1>Medicus ERP V4</h1>
    <nav class="nav-buttons">
      <router-link :to="'/'" v-if="user">
        <button>Orders</button>
      </router-link>
      <router-link :to="'/supplies'" v-if="user">
        <button>Manage Supplies</button>
      </router-link>
      <button @click="signOut" v-if="user">Sign Out</button>
      <router-link :to="'/login'" v-if="!user">
        <button>Login</button>
      </router-link>
    </nav>
  </header>
  <main>
    <router-view></router-view>
  </main>
  <footer v-if="user">
    Logged in as: {{ user.email }}
    <button @click="signOut" class="signout-button">Sign Out</button>
  </footer>
</div>
</template>

<script>
import { supabase } from './supabase';

export default {
data() {
  return {
    user: null
  };
},
async created() {
  this.checkSession();
},
methods: {
  async checkSession() {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      this.user = session.user;
      // Redirect to the orders page after login
      if (this.$route.path === '/login') {
        this.$router.push('/');
      }
    } else {
      this.$router.push('/login');
    }
  },
  async signOut() {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
    } else {
      this.user = null;
      this.$router.push('/login');
    }
  }
},
watch: {
  '$route'(to, from) {
    // Check session whenever the route changes
    this.checkSession();
  }
}
};
</script>

<style scoped>
/* Styling for the layout */
header {
background-color: #343a40;
color: white;
padding: 15px;
text-align: center;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

header h1 {
font-size: 24px;
margin: 0 0 10px 0;
}

.nav-buttons {
display: flex;
justify-content: center;
gap: 10px; /* Spacing between buttons */
margin-top: 10px;
}

nav button {
padding: 10px 15px;
background-color: #007bff;
color: white;
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s ease;
}

nav button:hover {
background-color: #0056b3;
}

main {
padding: 20px;
}

footer {
background-color: #f8f9fa;
padding: 10px;
text-align: center;
border-top: 1px solid #e0e0e0;
}

.signout-button {
padding: 8px 12px;
background-color: #dc3545;
color: white;
border: none;
border-radius: 5px;
cursor: pointer;
}

.signout-button:hover {
background-color: #c82333;
}
</style>
<template>
<div class="supplier-orders">
  <h1>Supply Orders Management</h1>

  <!-- New Supply Order Section -->
  <div class="new-supply-order-box">
    <h2>Request New Supply</h2>
    <input v-model="newSupplyName" placeholder="Supply Name" />
    <input v-model.number="newSupplyAmount" placeholder="Amount" type="number" />
    <select v-model="newSupplyType">
      <option value="General Supplies">General Supplies</option>
      <option value="Embroidery">Embroidery</option>
      <option value="Plain Fabric Oddies">Plain Fabric Oddies</option>
      <option value="Patterned Fabric Reorder">Patterned Fabric Reorder</option>
    </select>
    <select v-model="newSupplyStatus">
      <option value="requested">Requested</option>
      <option value="ordered">Ordered</option>
      <option value="delivered">Delivered</option>
    </select>
    <button @click="addSupplyOrder" :disabled="!newSupplyName || !newSupplyAmount || !newSupplyType">Add Supply</button>
    <div v-if="orderErrorMessage" class="error-message">
      {{ orderErrorMessage }}
    </div>
    <div v-if="orderSuccessMessage" class="success-message">
      {{ orderSuccessMessage }}
    </div>
  </div>

  <!-- Legend Section -->
  <div class="legend">
    <h2>Legend</h2>
    <p>Items can be selected and status updated, to make it easier we have a traffic light system.</p>
    <ul>
      <li class="legend-item">
        <span class="legend-color requested"></span> Requested (Red)
      </li>
      <li class="legend-item">
        <span class="legend-color ordered"></span> Ordered (Yellow)
      </li>
      <li class="legend-item">
        <span class="legend-color delivered"></span> Delivered (Green)
      </li>
    </ul>
  </div>

  <!-- Supplies Organized by Type and Sorted by Status -->
  <div v-for="type in supplyTypes" :key="type" class="supply-type-section">
    <h2>{{ type }}</h2>
    <ul v-if="sortedSupplies(type).length">
      <li v-for="order in sortedSupplies(type)" :key="order.id" :class="order.status">
        <router-link :to="{ name: 'SupplyOrderDetails', params: { id: order.id } }">
          {{ order.supply_name }} - {{ order.amount }} units - 
          {{ new Date(order.created_at).toLocaleDateString('en-GB') }} 
          {{ new Date(order.created_at).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) }}
        </router-link>
      </li>
    </ul>
    <p v-else class="no-requests">No {{ type }} requests</p> <!-- Display message when no requests -->
  </div>
</div>
</template>

<script>
import { supabase } from '../supabase';

export default {
data() {
  return {
    supplies: [],
    newSupplyName: '',
    newSupplyAmount: null,
    newSupplyType: 'General Supplies', // Default type
    newSupplyStatus: 'requested',
    orderErrorMessage: '',
    orderSuccessMessage: '',
    supplyTypes: ['General Supplies', 'Embroidery', 'Plain Fabric Oddies', 'Patterned Fabric Reorder'] // Predefined types
  };
},
async created() {
  await this.fetchSupplies();
},
methods: {
  async fetchSupplies() {
    try {
      const { data: suppliesData, error } = await supabase
        .from('supplies')
        .select('*')
        .eq('archived', false) // Exclude archived records
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Error fetching supplies:', error);
        this.orderErrorMessage = 'Failed to load supplies.';
      } else {
        this.supplies = suppliesData;
      }
    } catch (err) {
      console.error('Failed to fetch supplies:', err);
      this.orderErrorMessage = 'Failed to load supplies due to an error.';
    }
  },
  async addSupplyOrder() {
    if (!this.newSupplyName || !this.newSupplyAmount || !this.newSupplyType) {
      this.orderErrorMessage = 'Supply Name, Amount, and Type cannot be empty.';
      return;
    }

    try {
      const { data, error } = await supabase
        .from('supplies')
        .insert([
          { 
            supply_name: this.newSupplyName, 
            amount: this.newSupplyAmount,
            type: this.newSupplyType, // Include type
            status: this.newSupplyStatus 
          }
        ])
        .select();

      if (error) {
        console.error('Error creating supply order:', error);
        this.orderErrorMessage = `Failed to create supply order. ${error.message}`;
      } else if (data && data.length > 0) {
        this.supplies.push(data[0]); // Add the new order to the list
        this.newSupplyName = ''; // Clear the input fields
        this.newSupplyAmount = null;
        this.newSupplyType = 'General Supplies';
        this.newSupplyStatus = 'requested';
        this.orderErrorMessage = ''; // Clear any error messages
        this.orderSuccessMessage = 'Supply order successfully created!';

        // Hide success message after 3 seconds
        setTimeout(() => {
          this.orderSuccessMessage = '';
        }, 3000);
      } else {
        this.orderErrorMessage = 'Failed to create supply order. No data returned.';
      }
    } catch (err) {
      console.error('Failed to create supply order:', err);
      this.orderErrorMessage = `Failed to create supply order due to an error. ${err.message}`;
    }
  },
  sortedSupplies(type) {
    return this.supplies
      .filter(supply => supply.type === type)
      .sort((a, b) => {
        const statusOrder = { requested: 1, ordered: 2, delivered: 3 };
        return statusOrder[a.status] - statusOrder[b.status];
      });
  }
}
};
</script>

<style scoped>
/* Styling for the layout */
.legend {
margin-bottom: 20px;
}

.legend ul {
list-style-type: none;
padding: 0;
display: flex;
gap: 20px;
justify-content: center;
}

.legend-item {
display: flex;
align-items: center;
gap: 10px;
}

.legend-color {
display: inline-block;
width: 20px;
height: 20px;
border-radius: 4px;
}

.legend-color.requested {
background-color: rgba(255, 99, 71, 0.2); /* Light red */
}

.legend-color.ordered {
background-color: rgba(255, 215, 0, 0.2); /* Light yellow */
}

.legend-color.delivered {
background-color: rgba(144, 238, 144, 0.2); /* Light green */
}

.supply-type-section {
margin-bottom: 40px;
}

.supply-type-section h2 {
text-align: center;
margin-bottom: 10px;
color: #333;
}

ul {
list-style-type: none;
padding: 0;
}

li {
margin-bottom: 5px;
padding: 10px;
border-radius: 4px;
border: 1px solid #ddd;
}

li.requested {
background-color: rgba(255, 99, 71, 0.2); /* Light red */
}

li.ordered {
background-color: rgba(255, 215, 0, 0.2); /* Light yellow */
}

li.delivered {
background-color: rgba(144, 238, 144, 0.2); /* Light green */
}

.new-supply-order-box {
margin-bottom: 20px;
padding: 20px;
background-color: #f4f4f4;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.new-supply-order-box h2 {
margin-bottom: 10px;
text-align: center;
}

.new-supply-order-box input,
.new-supply-order-box select {
width: 100%;
margin-bottom: 10px;
padding: 10px;
border-radius: 4px;
border: 1px solid #ddd;
}

.new-supply-order-box button {
padding: 10px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.new-supply-order-box button:disabled {
background-color: #ccc;
cursor: not-allowed;
}

.error-message {
color: red;
margin-top: 10px;
font-weight: bold;
  text-align: center;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.no-requests {
  text-align: center;
  color: #777;
  font-style: italic;
  margin: 20px 0;
}
</style>
<template>
<div class="login">
  <h1>Login</h1>
  <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  <input v-model="email" placeholder="Email" />
  <input v-model="password" type="password" placeholder="Password" />
  <button @click="signIn">Sign In</button>
</div>
</template>

<script>
import { supabase } from '../supabase';

export default {
data() {
  return {
    email: '',
    password: '',
    errorMessage: '',
  };
},
methods: {
  async signIn() {
    try {
      // Attempt to sign in the user with the provided credentials
      const { error } = await supabase.auth.signInWithPassword({
        email: this.email,
        password: this.password,
      });

      // Check for errors returned from Supabase
      if (error) {
        this.errorMessage = 'Failed to sign in: ' + error.message;
        console.error('Error signing in:', error);
      } else {
        // If no error, clear the error message and redirect to the DataList page
        this.errorMessage = '';
        this.$router.push('/'); // Redirect to the data list page after login
      }
    } catch (err) {
      // Handle any unexpected errors
      this.errorMessage = 'Unexpected error occurred during sign in.';
      console.error('Unexpected error signing in:', err);
    }
  },
},
};
</script>

<style scoped>
.login {
max-width: 400px;
margin: 0 auto;
padding: 20px;
background-color: #f4f4f4;
border-radius: 8px;
}

input {
display: block;
width: 100%;
margin-bottom: 10px;
padding: 10px;
border: 1px solid #ddd;
border-radius: 4px;
}

button {
width: 100%;
padding: 10px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

button:hover {
background-color: #0056b3;
}

.error-message {
color: red;
margin-bottom: 10px;
}
</style>
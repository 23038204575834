<template>
<div class="data-list">
  <!-- New Order Creation Section -->
  <div class="new-order-box">
    <h2>Flag New Order Issues</h2>
    <input v-model="newOrderId" placeholder="Order ID like 6543" />
    <textarea v-model="newOrderNote" placeholder="Issue"></textarea>
    <select v-model="newOrderStatus">
      <option :value="true">Completed</option>
      <option :value="false">Not Completed</option>
    </select>
    <button @click="addOrder" :disabled="!newOrderId || !newOrderNote">Create Order</button>
    <div v-if="orderErrorMessage" class="error-message">
      {{ orderErrorMessage }}
    </div>
    <div v-if="orderSuccessMessage" class="success-message">
      {{ orderSuccessMessage }}
    </div>
  </div>

  <!-- Orders with Issues -->
  <h2>Orders With Issues</h2>
  <ul>
    <li v-for="order in ordersWithIssues" :key="order.id">
      <router-link :to="{ name: 'OrderDetails', params: { id: order.id } }">
        <strong>{{ order.order_id }}</strong>: {{ order.note }}
        <p></p>
      </router-link>
    </li>
  </ul>

  <!-- Completed Orders -->
  <h2>Completed Orders</h2>
  <ul>
    <li v-for="order in completedOrders" :key="order.id">
      <router-link :to="{ name: 'OrderDetails', params: { id: order.id } }">
        <strong>{{ order.order_id }}</strong>: {{ order.note }}
        <p></p>
      </router-link>
    </li>
  </ul>
</div>
</template>

<script>
import { supabase } from '../supabase';

export default {
data() {
  return {
    orders: [],
    newOrderId: '',
    newOrderNote: '',
    newOrderStatus: false,
    orderErrorMessage: '',
    orderSuccessMessage: ''
  };
},
async created() {
  await this.fetchOrders();
},
computed: {
  ordersWithIssues() {
    return this.orders.filter(order => !order.status);
  },
  completedOrders() {
    return this.orders.filter(order => order.status);
  }
},
methods: {
  async fetchOrders() {
    try {
      const { data: ordersData, error: ordersError } = await supabase
        .from('Notes')
        .select('*');

      if (ordersError) {
        console.error('Error fetching orders:', ordersError);
        this.orderErrorMessage = 'Failed to load orders.';
      } else {
        this.orders = ordersData;
      }
    } catch (err) {
      console.error('Failed to fetch orders:', err);
      this.orderErrorMessage = 'Failed to load orders due to an error.';
    }
  },
  async addOrder() {
    if (!this.newOrderId || !this.newOrderNote) {
      this.orderErrorMessage = 'Order ID and Note cannot be empty.';
      return;
    }

    try {
      const { data, error } = await supabase
        .from('Notes')
        .insert([
          { 
            order_id: this.newOrderId, 
            note: this.newOrderNote,
            status: this.newOrderStatus
          }
        ])
        .select();  // Ensure that the inserted data is returned

      if (error) {
        console.error('Error creating order:', error);
        this.orderErrorMessage = `Failed to create order. ${error.message}`;
      } else if (data && data.length > 0) {
        console.log("New order created:", data[0]);
        this.orders.push(data[0]);  // Add the new order to the list
        this.newOrderId = '';  // Clear the input fields
        this.newOrderNote = '';
        this.newOrderStatus = false;
        this.orderErrorMessage = ''; // Clear any error messages
        this.orderSuccessMessage = 'Order successfully created!';

        // Hide success message after 3 seconds
        setTimeout(() => {
          this.orderSuccessMessage = '';
        }, 3000);
      } else {
        this.orderErrorMessage = 'Failed to create order. No data returned.';
      }
    } catch (err) {
      console.error('Failed to create order:', err);
      this.orderErrorMessage = `Failed to create order due to an error. ${err.message}`;
    }
  },
},
};
</script>

<style scoped>
/* Your existing styles here */

.data-list {
max-width: 800px;
margin: 0 auto;
padding: 20px;
}

.new-order-box {
margin-bottom: 20px;
padding: 20px;
background-color: #f4f4f4;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.new-order-box h2 {
margin-bottom: 10px;
}

.new-order-box input,
.new-order-box textarea,
.new-order-box select {
width: 100%;
margin-bottom: 10px;
padding: 10px;
border-radius: 4px;
border: 1px solid #ddd;
}

.new-order-box button {
padding: 10px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.new-order-box button:disabled {
background-color: #ccc;
cursor: not-allowed;
}

.error-message {
color: red;
margin-top: 10px;
font-weight: bold;
}

.success-message {
color: green;
margin-top: 10px;
font-weight: bold;
}
</style>
<template>
<div class="supply-order-details">
  <router-link to="/supplies" class="back-button">Back to Supplies</router-link>

  <h1>Supply Order Details</h1>
  <div v-if="supplyOrder" class="order-info">
    <p><strong>Supply Name:</strong> {{ supplyOrder.supply_name }}</p>
    <p><strong>Amount:</strong> {{ supplyOrder.amount }} units</p>
    <p>
      <strong>Status:</strong>
      <select v-model="selectedStatus">
        <option value="requested">Requested</option>
        <option value="ordered">Ordered</option>
        <option value="delivered">Delivered</option>
      </select>
      <button @click="saveStatus" class="save-button">Save</button>
    </p>
    <div v-if="selectedStatus === 'delivered'" class="archive-option">
      <label>
        <input type="checkbox" v-model="archiveOrder" />
        Archive this order
      </label>
    </div>
    <div v-if="successMessage" class="success-message">
      {{ successMessage }}
    </div>
  </div>
  <div v-else>
    <p>Loading supply order details...</p>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</div>
</template>

<script>
import { supabase } from '../supabase';

export default {
props: ['id'],
data() {
  return {
    supplyOrder: null,
    selectedStatus: '',
    archiveOrder: false,
    successMessage: '',
    errorMessage: ''
  };
},
async created() {
  await this.fetchSupplyOrderDetails();
},
methods: {
  async fetchSupplyOrderDetails() {
    try {
      const { data, error } = await supabase
        .from('supplies')
        .select('*')
        .eq('id', this.id)
        .single(); // Ensure only one row is returned

      if (error || !data) {
        console.error('Error fetching supply order details:', error);
        this.errorMessage = 'Failed to load supply order details.';
      } else {
        this.supplyOrder = data;
        this.selectedStatus = data.status; // Initialize selected status
      }
    } catch (err) {
      console.error('Failed to load supply order details:', err);
      this.errorMessage = 'Failed to load supply order details due to an error.';
    }
  },
  async saveStatus() {
    try {
      const updateData = {
        status: this.selectedStatus,
        archived: this.archiveOrder // Set archive status
      };

      const { error } = await supabase
        .from('supplies')
        .update(updateData)
        .eq('id', this.id);

      if (error) {
        console.error('Error updating status:', error);
        this.errorMessage = 'Failed to update status. Please try again.';
      } else {
        this.successMessage = 'Status successfully updated!';
        this.supplyOrder.status = this.selectedStatus; // Update the local supply order object
        this.supplyOrder.archived = this.archiveOrder;

        // Hide success message after 3 seconds
        setTimeout(() => {
          this.successMessage = '';
        }, 3000);
      }
    } catch (err) {
      console.error('Failed to update status:', err);
      this.errorMessage = 'Failed to update status due to an error.';
    }
  }
}
};
</script>

<style scoped>
/* Your existing styles here */
.supply-order-details {
max-width: 600px;
margin: 0 auto;
padding: 20px;
background-color: #f4f4f4;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.back-button {
display: inline-block;
margin-bottom: 20px;
padding: 10px 20px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
text-decoration: none;
text-align: center;
}

.back-button:hover {
background-color: #0056b3;
}

.supply-order-details h1 {
text-align: center;
color: #333;
font-size: 24px;
margin-bottom: 20px;
}

.order-info p {
margin: 10px 0;
font-size: 16px;
}

.order-info select {
padding: 5px;
font-size: 16px;
margin-right: 10px;
}

.save-button {
padding: 5px 10px;
background-color: #28a745;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.save-button:hover {
background-color: #218838;
}

.success-message {
margin-top: 10px;
color: #28a745;
font-weight: bold;
}

.error-message {
margin-top: 10px;
color: red;
font-weight: bold;
}

.archive-option {
margin-top: 15px;
}
</style>
import { createRouter, createWebHistory } from 'vue-router';
import DataList from '../components/DataList.vue';
import OrderDetails from '../components/OrderDetails.vue';
import SupplierOrders from '../components/SupplierOrders.vue';
import SupplyOrderDetails from '../components/SupplyOrderDetails.vue'; // Import the new component
import Login from '../components/Login.vue'; // Import the Login component

const routes = [
  { path: '/', name: 'DataList', component: DataList },
  { path: '/order/:id', name: 'OrderDetails', component: OrderDetails, props: true },
  { path: '/supplies', name: 'SupplierOrders', component: SupplierOrders },
  { path: '/supply/:id', name: 'SupplyOrderDetails', component: SupplyOrderDetails, props: true }, // Add the route for the new component
  { path: '/login', name: 'Login', component: Login },
  { path: '/:pathMatch(.*)*', redirect: '/login' }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;